@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/digital-numbers");

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html,
body {
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 7px; /* Customize scrollbar width */
    border-radius: 50%; /* Rounded borders for scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #3d3a3a; /* Color for the active scroll section */
    border-radius: 50px; /* Rounded borders for scrollbar */
}

::-webkit-scrollbar-track {
    background: lightgray; /* Background for the scroll track */
}
